import axios from "axios";

export const callApiValidateSession = async (token: string) => {
  const params = new URLSearchParams();
  params.append("d", token);

  try {
    console.time("validate session");
    const response = await axios.get(`/api/validateSession`, { params });
    console.timeEnd("validate session");
    return response.data;
  } catch (error) {
    throw error;
  }
};
