import { CheckoutType } from "../app/types/checkoutType";
import axios from "axios";

export const callApiSendOrder = async (checkout: CheckoutType) => {
  try {
    const response = await axios.post("/api/sendOrder", checkout);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error);
      throw new Error(error.code + " " + error.message);
    } else {
      console.log(error);
      throw error;
    }
  }
};
