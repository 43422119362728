import { TypeRangeCollection } from "./../types/types";
/**
 * CATEGORIE
 */
export const categoryList: TypeCategory[] = [
  { key: "595316", value: "Buoni" },
  { key: "24773", value: "Casa e Design" },
  { key: "24772", value: "Sport e Hobby" },
  { key: "24776", value: "Esperienze e Servizi" },
  { key: "24775", value: "Hi-Tec" },
  { key: "24774", value: "Gourmet" },
  { key: "24771", value: "Per Lui" },
  { key: "24711", value: "Per Lei" },
  { key: "76809", value: "Bambini" },
  { key: "598752", value: "Exclusive" },
];

export type TypeCategory = {
  key: string;
  value: string;
};

export type TypeQuerySearch = {
  categories: TypeCategory[];
  keyword: string;
  rangeValue: number[];
};

// FIXME: spostare in configurazione o comunque esterna al deploy
export const rangeCollection: TypeRangeCollection[] = [
  { idCollection: "2f24db7aaecb6a0eb857b1899970f847", amount: 1 },
  { idCollection: "dcc416e05f405e3eac97b51994a4bd0a", amount: 5 },
  { idCollection: "5777b5a7036e4af48dffaf1ec5fe185e", amount: 10 },
  { idCollection: "bc10b60d67500433bbff8ddda1f915a0", amount: 15 },
  { idCollection: "a5946e6222fdfa9ea0cda923ab1335a4", amount: 20 },
  { idCollection: "4a96d9e12f0989c6011220747c06b380", amount: 25 },
  { idCollection: "d1a5369139bc9e58fc20869bc0cfd291", amount: 35 },
  { idCollection: "778450a2ff6647d66f9259b7c39173a9", amount: 50 },
  { idCollection: "15dfce915ebcccb5c114c57e559672e5", amount: 75 },
  { idCollection: "56abedb9db9141c04d35a1763db82ead", amount: 90 },
  { idCollection: "fe638f1efcdd1e284ddb980c840fc890", amount: 100 },
  { idCollection: "b0f91620c25469f9ef5dbcbb6d76d81e", amount: 125 },
  { idCollection: "22e92c0b8b3ab7152512e578581d7e05", amount: 150 },
  { idCollection: "8eccaf75989960a1e6f6645404cc3418", amount: 200 },
  { idCollection: "eb0384762b962923553bc74d76aa031b", amount: 250 },
  { idCollection: "ce7178d4c05c09b163dc96402939c422", amount: 300 },
  { idCollection: "ec8a903a09a20eea385bfe443284d292", amount: 500 },
  { idCollection: "8916e3698ba3f91440751a6f45857093", amount: 750 },
  { idCollection: "03004b1b7360b7fc99f436a7638b082c", amount: 1000 },
];

export const REACT_APP_MIN_FILTER = process.env.REACT_APP_MIN_FILTER
  ? parseInt(process.env.REACT_APP_MIN_FILTER)
  : 0;
export const REACT_APP_MAX_FILTER = process.env.REACT_APP_MAX_FILTER
  ? parseInt(process.env.REACT_APP_MAX_FILTER)
  : 1000;
