import axios from "axios";
import { bconf, rangeCollection, TypeQuerySearch } from "../../app/index";
import { TypeT } from "./../../app/types/types";

export const getProducts = async (
  currentPage: number,
  collection: string,
  excludeCategories: string,
  querySearch: TypeQuerySearch,
  token: TypeT
) => {
  const url = token.ApiBaseURL;
  const user = token.ApiCRMUsername;
  const cat = querySearch.categories.map((elem) => elem.key).join(",");
  const collections = getCollections(querySearch);

  const callUrl = `${url}Collection?sessiontoken=${encodeURIComponent(
    token.value
  )}&idcollection=${encodeURIComponent(
    collections
  )}&username=${encodeURIComponent(user)}&offset=${
    bconf().totalProductPerPage
  }&page=${currentPage}&categories=${cat}&filter=${encodeURIComponent(
    querySearch.keyword
  )}&categories_to_exclude=${encodeURIComponent(excludeCategories)}`;

  try {
    const result = await axios({
      method: "get",
      url: callUrl,
    });

    return result;
  } catch (error: any) {
    throw error;
  }
};

const getCollections = (querySearch: TypeQuerySearch): string => {
  const collections = rangeCollection.filter((collection) => {
    return (
      collection.amount >= querySearch.rangeValue[0] &&
      collection.amount <= querySearch.rangeValue[1]
    );
  });

  return collections.map((elem) => elem.idCollection).join(",");
};
