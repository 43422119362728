export const decodeHtmlCharCodes = (str?: string) => {
  if (str) {
    try {
      return str.replace(/(&#(\d+);)/g, (match, capture, charCode) =>
        String.fromCharCode(charCode)
      );
    } catch (e) {
      console.log(e);
    }
  } else {
    return "";
  }
};
