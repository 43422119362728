const colors = {
  wishlist: 
  {
    50: '#fffedd',
    100: '#fcf6b3',
    200: '#f8ec85',
    300: '#d9d711',
    400: '#d9d711',
    500: '#a0a909',
    600: '#B9CE08',
    700: '#6b7904',
    800: '#394800',
    900: '#141a00',
  },
};

export default colors;


