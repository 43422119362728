import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Image
} from "@chakra-ui/react";
import * as React from "react";
import { WBToken } from "../app/index";
import { HelpBanner } from "../components/helpBanner/helpBanner";
import { User } from "../components/user/user";
import { useHelpBanner } from "../hooks/useHelpBanner";


export const MenuHeader = (user:WBToken) => {
  const { helpBtnRef, helpIsOpen, helpOnOpen, helpOnClose } = useHelpBanner();

  return (
    <Grid h="100px" templateColumns="repeat(2, 1fr)" gap={2} w="100%" bg={process.env.REACT_APP_HEADER_BG}>
      <GridItem >
        <Box p={5}>
          <Image
            float="left"
            src={process.env.REACT_APP_LOGO }
            w={["80px", "100px"]}            
          />
        </Box>
      </GridItem>
      <GridItem>
        <Box p={5}>
          <ButtonGroup
            float="right"
            variant="ghost"
            spacing="3"
            colorScheme="wishlist"
          >
            <Button bgColor="#fff"
              leftIcon={<InfoIcon />}
              ref={helpBtnRef}
              onClick={helpOnOpen}
              fontSize={["sm", "md"]}
              size={"sm"}
            >
              Aiuto
            </Button>
            {user && <User {...user} />}
     
          </ButtonGroup>
          <HelpBanner
            isOpen={helpIsOpen}
            onClose={helpOnClose}
            btnRef={helpBtnRef}
          />
        </Box>
      </GridItem>
     
    </Grid>
  );
};
