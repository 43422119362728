import { Box, Image } from "@chakra-ui/react";
import React from "react";

export const PopOverBodyFilter = () => {
  return (
    <Box>
      <Image
        borderRadius="15px"
        src="https://shop.wishlist.it/wp-content/uploads/2022/03/welfarebit-banner.png"
      />
    </Box>
  );
};
