import {
  Alert,
  AlertDescription,
  // AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Text,
} from "@chakra-ui/react";
import { ErrorType } from "../../app/index";

interface CodeAlertProps {
  isOpen: boolean;
  error: ErrorType | undefined;
  onClose?: () => void;
}

export const CodeAlert = ({ isOpen, error, onClose }: CodeAlertProps) => {
  return (
    <>
      {isOpen && (
        <Alert
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="md"
          width="2xl"
          borderRadius="15px"
          colorScheme="wishlist"
          status={error?.status ? "error" : "success"}
        >
          <AlertIcon boxSize="10" color={"wishlist.500"} />
          <Box>
            <AlertTitle color="wishlist.700" mr={2} mt="1em">
              {error?.msg}
            </AlertTitle>
            <AlertDescription color="wishlist.700">
              Riprovare ad effettuare l'accesso.
            </AlertDescription>
            <Text fontSize={"0.7em"}>v. {process.env.REACT_APP_VERSION}</Text>
          </Box>

          <CloseButton
            size="md"
            color="wishlist.500"
            onClick={onClose}
            position="absolute"
            right="8px"
            top="8px"
          />
        </Alert>
      )}
    </>
  );
};
