import { InfoIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Center,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  VStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useCheckout } from "../../hooks/useCheckout";
import { useNavigate } from "react-router-dom";
import ErrorView  from "../errorView/errorView";
import { decodeHtmlCharCodes } from "../../utils/stringCodeFormatter";


export const ProcessOrder = () => {
  const { checkout } = useCheckout();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };

  return checkout.error ? (
    <ErrorView
      error={{ msg: checkout.error?.message, status: checkout.error?.status }}
    />
  ) : (
    <Box p={"1em"} color={"wishlist.500"}>
      <Heading
        as="h1"
        lineHeight={1.1}
        fontSize={{ base: "2xl", sm: "3xl" }}
        mb="1em"
      >
        {checkout.receipt && checkout.receipt.status === "Success"
          ? `Conferma ordine n. ${checkout.receipt.result}`
          : "Si è verificato un errore. L'ordine non è stato processato"}
      </Heading>

      <SimpleGrid columns={[1, 2, 2]} mb="2em">
        <Box>
          <Heading pb={3} as="h6" fontSize={"1em"} fontWeight="semibold">
            Indirizzo di spedizione:
          </Heading>
          <Text>{checkout.indirizzo}</Text>
          <Text>
            {`${checkout.cap} - ${checkout.citta} - ${checkout.provincia}`}{" "}
          </Text>
          <Text>Note: {checkout.note}</Text>
        </Box>
        <Box>
          <Heading pb={3} as="h6" fontSize={"1em"} fontWeight="semibold">
            Dati di contatto:
          </Heading>
          <Text>Telefono: {checkout.telefono}</Text>
          <Text>Indirizzo email: {checkout.email}</Text>
        </Box>
      </SimpleGrid>
      <Alert status="info" colorScheme="wishlist">
        <InfoIcon color="wishlist.500" m="0.4em" fontSize="2xl" />
        <Box flex="1">
          <AlertTitle color="wishlist.700">
            Conserva il tuo numero di ordine.
          </AlertTitle>
          <AlertDescription color="wishlist.700" display="block">
            Per assistenza chiamaci al numero{" "}
            <Link fontWeight="bold" href="tel:0236638510">
              02 3663 8510
            </Link>{" "}
            o scrivici a{" "}
            <Link fontWeight="bold" href="mailto:ordini@wishlist.it">
              ordini@wishlist.it
            </Link>
            .
          </AlertDescription>
        </Box>
      </Alert>
      <VStack p={"2em"} color="wishlist.500">
        <Button
          onClick={handleClick}
          bgColor={"wishlist.400"}
          color={"#ffffff"}
          _hover={{ bg: "wishlist.600" }}
          _active={{
            bg: "wishlist.600",
            transform: "scale(0.98)",
            borderColor: "wishlist.600",
          }}
        >
          Continua lo shopping
        </Button>
        <Text>
          Con il tuo credito puoi richiedere un altro buono o prodotto
        </Text>
      </VStack>
      <Stack
        mt="1em"
        w={"100%"}
        border={"1px solid #d3d3d394"}
        direction={["column", "column", "row"]}
      >
        <Center p="1em">
          <Image
            boxSize={["100px", "150px", "xs"]}
            src={`${checkout.prodotto?.baseURL}${checkout.prodotto?.imagename}`}
            alt={decodeHtmlCharCodes(checkout.prodotto?.descrizione)}
          />
          <Box w={["xs", "md"]} p={2}>
            <Heading fontSize={["xs", "xs", "md"]} mb="1em">
              {decodeHtmlCharCodes(checkout.prodotto?.productname)}
            </Heading>
            <Text fontSize={["xs", "md"]}>
              {decodeHtmlCharCodes(checkout.prodotto?.descrizione)}
            </Text>
          </Box>
        </Center>
      </Stack>
    </Box>
  );
};
