import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  VStack
} from "@chakra-ui/react";
import * as React from "react";
import { BadgeCredit } from "../../badgeCredit";

export interface RangeBarProps {
  walletTotale: number;
  walletBuoni: number;
  rangeValue: number[];
  setRangeValue: (rangeValue: number[]) => void;
}

const RangeBar = ({
  rangeValue,
  setRangeValue,
  walletBuoni,
  walletTotale,
}: RangeBarProps) => {
  return (
    <>
      <VStack>
        <BadgeCredit credit100={walletTotale} credit51c3={walletBuoni} />
        <Text fontSize={"sm"} fontWeight="bold" color={"wishlist.500"}>
          Fascia da: {rangeValue[0]}€ a {rangeValue[1]}€
        </Text>
      </VStack>

      <RangeSlider
        defaultValue={[rangeValue[0], rangeValue[1]]}
        min={0}
        max={1000}
        step={10}
        onChangeEnd={(val) => setRangeValue(val)}
      >
        <RangeSliderTrack bg="wishlist.100" aria-label={"min"}>
          <RangeSliderFilledTrack bg="wishlist.500" />
        </RangeSliderTrack>
        <RangeSliderThumb boxSize={6} index={0} aria-label={"max"}>
          <Box color="wishlist.500" as={TriangleDownIcon} />
        </RangeSliderThumb>
        <RangeSliderThumb boxSize={6} index={1}>
          <Box color="wishlist.500" as={TriangleDownIcon} />
        </RangeSliderThumb>
      </RangeSlider>
    </>
  );
};

export default RangeBar;
