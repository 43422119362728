import {
  Box,
  Center, Container,
  Divider,
  Link,
  Stack,
  useColorModeValue
} from "@chakra-ui/react";
import { Link as ReachLink } from "react-router-dom";

export function FooterSection() {
  return (
    <Box color={useColorModeValue("wishlist.500", "wishlist.500")} w={"90%"}>
      <Divider mt={"3em"} borderColor={"wishlist.500"} />
      <Container
        as={Stack}
        maxW={"7xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Stack direction={"row"} spacing={3}>
          <Link as={ReachLink} to="/">
            Home
          </Link>
          <Link
            href="https://www.wishlist.it/privacy-and-cookies-law"
            target={"_blank"}
          >
            Privacy policy
          </Link>
        </Stack>
      </Container>

      <Container
        as={Stack}
        maxW={"7xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Center>
          © 2022 Sede legale Wishlist Srl - via Rimini n. 24 - 20142 Milano -
          P.IVA: 07122580967 - V. {process.env.REACT_APP_VERSION }
        </Center>
      </Container>
    </Box>
  );
}
