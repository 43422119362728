import React from "react";
import * as serviceWorker from "./serviceWorker";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { MyRoutes } from "./app/index";
import { wlTheme } from "./theme/wishlistTheme";
import "@fontsource/nunito/300.css";
import "@fontsource/ibm-plex-serif/300.css";
import { Provider } from "react-redux";
import store from "./context/store";
import { createRoot } from 'react-dom/client';




const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={wlTheme}>
        <ColorModeScript />
        <MyRoutes />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
);

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./app/App', render)
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
